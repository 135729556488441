import "./App.css";
import Card from "./components/card";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="hero-section">
     
    </div>
  );
}

export default App;
