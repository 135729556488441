import axios, { AxiosInstance } from 'axios'

var baseURL = 'https://api.coingecko.com/api/v3/simple/';

const api: AxiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 30000, // 30 secs
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export async function apiGeckoSimpleGetPrice(ids:string, vs_currencies:string): Promise<IAssetData[]> {
  const response = await api.get(
    `/price?ids=${ids}&vs_currencies=${vs_currencies}`
  )
  
  return response
}

export function thousands_separators(num, digit) {
	if(typeof digit === 'undefined'){
		digit = 8;
	}
	
	if(isNaN(num)){
		return 0;
	} else {
		var numFloat = parseFloat(num);
			numFloat = numFloat.toFixed(digit);
			numFloat = parseFloat(numFloat);
			
			var num_parts = numFloat.toString().split(".");
			if(typeof num_parts[1] !== 'undefined'){
				let number = num_parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
				let decimal = num_parts[1];
				decimal = decimal.substr(0, digit);
				let value = 0;
				if(parseInt(decimal) > 0){
					value = number + '.'+ decimal;
				} else {
					value = number;
				}
				
				return value;
			} else {
				return numFloat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			}
	}		
}

export function isObject(obj: any): boolean {
  return typeof obj === "object" && !!Object.keys(obj).length;
}


export function stringToDate(string) {
	var _return = "";
	
	var date = new Date(string);
	var years = date.getFullYear();
	var months = date.getMonth() + 1;
	if(months < 10){
		months = "0" + months;
	}
	var days = date.getDate();
	if(days < 10){
		days = "0" + days;
	}
	
	var hours = date.getHours();
	if(hours < 10){
		hours = "0" + hours;
	}
	var minutes = "0" + date.getMinutes();
	if(minutes < 10){
		minutes = "0" + minutes;
	}
	var seconds = "0" + date.getSeconds();
	if(seconds < 10){
		seconds = "0" + seconds;
	}

	// Will display time in 10:30:23 format
	_return = years + "-" + months + "-" + days + " " + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
	
	return _return;
}

export function  stringToUnix(string){
	var _return = "";
	
	var date = new Date(string);
	
	_return = Math.floor(date.getTime() / 1000);
	
	return _return;
}

export function  unixToDate(unix_timestamp) {
	var _return = "";
	
	var date = new Date(unix_timestamp * 1000);
	var years = date.getFullYear();
	var months = date.getMonth() + 1;
	if(months < 10){
		months = "0" + months;
	}
	var days = date.getDate();
	if(days < 10){
		days = "0" + days;
	}
	
	var hours = date.getHours();
	if(hours < 10){
		hours = "0" + hours;
	}
	var minutes = "0" + date.getMinutes();
	if(minutes < 10){
		minutes = "0" + minutes;
	}
	var seconds = "0" + date.getSeconds();
	if(seconds < 10){
		seconds = "0" + seconds;
	}

	// Will display time in 10:30:23 format
	_return = years + "-" + months + "-" + days + " " + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
	
	return _return;
}

// export function timeInterval(second_inteval){
	// let date = new Date();
	// let	timestamp = Math.floor(date.getTime() / 1000);
	// let distance = second_inteval - timestamp;
	
	// if(distance < 0){
		// distance = 0;
	// }
	
	// let _return = "";
	
	// var days = Math.floor(distance / (60 * 60 * 24));
	// var hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60));
	// var minutes = Math.floor((distance % (60 * 60)) / (60));
	// var seconds = Math.floor((distance % (60)));
	
	// if(hours < 10){
		// hours = "0" + hours;
	// }
	// if(minutes < 10){
		// minutes = "0" + minutes;
	// }
	// if(seconds < 10){
		// seconds = "0" + seconds;
	// }
	
	// if(days > 0){
		// _return = _return + days + ":" + hours + ":" + minutes + ":" + seconds;
	// } else {
		// _return = hours + ":" + minutes + ":" + seconds;
	// }
	
	// return _return;

// }

export function timestamp(){
	let date = new Date();
	let	timestamp = Math.floor(date.getTime() / 1000);
	
	return timestamp;

}

export function timeInterval(intervalid, second_inteval){
	window.timeInterval(intervalid, second_inteval);
}

export function intToString(value) {
	var suffixes = ["", "K", "M", "B","T"];
	var suffixNum = Math.floor((""+value).length/3);
	var shortValue = value;
	if(suffixNum > 0){
		shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000,suffixNum)) : value).toFixed(2));
		if (shortValue % 1 !== 0) {
			shortValue = shortValue.toFixed(1);
		}
	}
	
	return shortValue+suffixes[suffixNum];
}
