import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@rainbow-me/rainbowkit/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import icon from "../src/assets/favicon.png";

import {
  RainbowKitProvider,
  connectorsForWallets,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import {
  metaMaskWallet,
  trustWallet,
  walletConnectWallet,
  coinbaseWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";

const POM_Mainnet = {
  id: 1,
  name: "Ethereum Mainnet",
  network: "Ethereum Mainnet",
  iconUrl: { icon },
  iconBackground: "#000",
  nativeCurrency: {
    decimals: 18,
    name: "Ethereum Mainnet",
    symbol: "ETH",
  },
  rpcUrls: {
    default: "https://ethereum.blockpi.network/v1/rpc/public",
  },
  blockExplorers: {
    default: { name: "Ethereum Mainnet", url: "https://etherscan.io" },
    mainn: { name: "Ethereum Mainnet", url: "https://etherscan.io" },
  },
  testnet: false,
};

const { provider, chains } = configureChains(
  [POM_Mainnet],
  [jsonRpcProvider({ rpc: (chain) => ({ http: chain.rpcUrls.default }) })]
);
const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      metaMaskWallet({ chains }),
      trustWallet({ chains }),
      walletConnectWallet({ chains }),
    ],
  },
  {
    groupName: "Others",
    wallets: [coinbaseWallet({ chains })],
  },
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <WagmiConfig client={wagmiClient}>
    <RainbowKitProvider
      chains={chains}
      modalSize="compact"
      theme={darkTheme({
        accentColor: "#573cff",
        accentColorForeground: "white",
        borderRadius: "medium",
        fontStack: "system",
        overlayBlur: "small",
      })}
    >
      <App />
    </RainbowKitProvider>
  </WagmiConfig>
);

reportWebVitals();
